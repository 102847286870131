const resource = '/admin/';

export default class Admin {
  constructor(api) {
    this.api = api;
  }

  getAllChallenges() {
    return this.api.get(`${resource}challenge/all`);
  }

  getApprovedChallenges() {
    return this.api.get(`${resource}challenge/approved`);
  }

  getUnapprovedChallenges() {
    return this.api.get(`${resource}challenge/unapproved`);
  }

  getReturnedChallenges() {
    return this.api.get(`${resource}challenge/returned`);
  }

  approveChallenge(id) {
    return this.api.get(`${resource}challenge/${id}/approve`);
  }

  returnChallenge(id) {
    return this.api.get(`${resource}challenge/${id}/return`);
  }

  // update(id, payload) {
  //   return this.api.put(`${resource}${id}`, payload);
  // }

  async delete(id) {
    return await this.api.delete(`${resource}${id}`);
  }

  getStats() {
    return this.api.get(`${resource}stats`);
  }

  updateChallenges() {
    return this.api.get(`${resource}updatechallenges`);
  }

  emailBlast() {
    return this.api.get(`${resource}emailblast`);
  }



  // region Users

  async masquerade(id) {
    return await this.api.get(`${resource}masquerade/${id}`);
  }

  // endregion
}
