export const meter1data = [
  ['2017-12-21', 1.42969026151345],
  ['2017-12-22', 1.21872955534571],
  ['2017-12-23', 1.77446340309199],
  ['2017-12-24', 1.76738060446997],
  ['2017-12-25', 1.84599910877394],
  ['2017-12-26', 2.81211479917373],
  ['2017-12-27', 1.5916652999615],
  ['2017-12-28', 1.35926111516884],
  ['2017-12-29', 2.30685451376067],
  ['2017-12-30', 2.39296693140737],
  ['2017-12-31', 2.85495051823623],
  ['2018-01-01', 1.36373811462446],
  ['2018-01-02', 1.25791456938945],
  ['2018-01-03', 0.522754074004049],
  ['2018-01-04', 1.17385331425332],
  ['2018-01-05', 1.96523057809646],
  ['2018-01-06', 2.03798799131448],
  ['2018-01-07', 1.32157833051898],
  ['2018-01-08', 0.991589614294445],
  ['2018-01-09', 1.47659826712408],
  ['2018-01-10', 0.856618844306315],
  ['2018-01-11', 0.604734082431082],
  ['2018-01-12', 2.41580295081324],
  ['2018-01-13', 2.16152519639457],
  ['2018-01-14', 0.967295698323202],
  ['2018-01-15', 2.23100472923835],
  ['2018-01-16', 0.828751010528973],
  ['2018-01-17', 0.67509021238597],
  ['2018-01-18', 0.649748535697596],
  ['2018-01-19', 1.51492386763253],
  ['2018-01-20', 0.713500087450104],
  ['2018-01-21', 1.27350635876396],
  ['2018-01-22', 2.48071100505121],
  ['2018-01-23', 2.87498497845637],
  ['2018-01-24', 0.705196548291997],
  ['2018-01-25', 1.64589559581689],
  ['2018-01-26', 0.60267736097942],
  ['2018-01-27', 1.97656075423193],
  ['2018-01-28', 0.771609243175801],
  ['2018-01-29', 2.1006364067069],
  ['2018-01-30', 0.684377141272474],
  ['2018-01-31', 0.576007343172937],
  ['2018-02-01', 2.31906505524693],
  ['2018-02-02', 1.18126161463496],
  ['2018-02-03', 0.849993911680698],
  ['2018-02-04', 1.52464913563225],
  ['2018-02-05', 2.66275600406718],
  ['2018-02-06', 2.18872692981666],
  ['2018-02-07', 2.39579217692868],
  ['2018-02-08', 0.726650943358898],
  ['2018-02-09', 2.86588020448214],
  ['2018-02-10', 1.49916069708868],
  ['2018-02-11', 2.29556111637216],
  ['2018-02-12', 2.58066626880478],
  ['2018-02-13', 1.60054527554451],
  ['2018-02-14', 2.43215408286609],
  ['2018-02-15', 1.83196239138836],
  ['2018-02-16', 2.29460761244995],
  ['2018-02-17', 1.83868353799892],
  ['2018-02-18', 2.76887383911501],
  ['2018-02-19', 2.61514524370269],
  ['2018-02-20', 1.08332252432565],
  ['2018-02-21', 1.39858584931488],
  ['2018-02-22', 0.541784931354607],
  ['2018-02-23', 0.857556440634395],
  ['2018-02-24', 0.880305754204266],
  ['2018-02-25', 2.58286148136309],
  ['2018-02-26', 2.78433694508159],
  ['2018-02-27', 0.679074061862678],
  ['2018-02-28', 1.36821340042831],
  ['2018-03-01', 1.41436330957432],
  ['2018-03-02', 2.46776698610122],
  ['2018-03-03', 2.70987672387482],
  ['2018-03-04', 0.78492007086938],
  ['2018-03-05', 0.933670600109258],
  ['2018-03-06', 2.13025185331874],
  ['2018-03-07', 1.01521949612967],
  ['2018-03-08', 2.26213025066151],
  ['2018-03-09', 0.550067816162636],
  ['2018-03-10', 1.53423432257637],
  ['2018-03-11', 1.33558055808141],
  ['2018-03-12', 0.844965057627898],
  ['2018-03-13', 2.38641327296667],
  ['2018-03-14', 1.66505524353209],
  ['2018-03-15', 2.97206556275872],
  ['2018-03-16', 2.29900848201547],
  ['2018-03-17', 2.6469264720987],
  ['2018-03-18', 2.89385956752577],
  ['2018-03-19', 1.47113083444105],
  ['2018-03-20', 2.41396232557413],
  ['2018-03-21', 1.62650809212373],
  ['2018-03-22', 1.3862643501249],
  ['2018-03-23', 2.61533100936264],
  ['2018-03-24', 0.975057912346157],
  ['2018-03-25', 1.94649735475198],
  ['2018-03-26', 1.94026460960706],
  ['2018-03-27', 1.99526260437849],
  ['2018-03-28', 1.85360972023357],
  ['2018-03-29', 0.609758119081943],
  ['2018-03-30', 0.808533059355144],
  ['2018-03-31', 2.53674931941856],
  ['2018-04-01', 1.93762942604345],
  ['2018-04-02', 2.91755237616057],
  ['2018-04-03', 1.09624415454507],
  ['2018-04-04', 2.67965154278712],
  ['2018-04-05', 1.27017783650931],
  ['2018-04-06', 2.29309539008393],
  ['2018-04-07', 1.5019976431126],
  ['2018-04-08', 0.623012529073716],
  ['2018-04-09', 1.62762643804227],
  ['2018-04-10', 1.65639030749128],
  ['2018-04-11', 2.08237342768277],
  ['2018-04-12', 1.24123118692823],
  ['2018-04-13', 1.20508007014877],
  ['2018-04-14', 2.4785541307002],
  ['2018-04-15', 1.44082482641474],
  ['2018-04-16', 2.23679309775826],
  ['2018-04-17', 2.79184507876106],
  ['2018-04-18', 1.47108125009525],
  ['2018-04-19', 1.88347327417349],
  ['2018-04-20', 0.88125143424201],
  ['2018-04-21', 2.50271120714318],
  ['2018-04-22', 0.522218505541667],
  ['2018-04-23', 1.6787514492291],
  ['2018-04-24', 2.99721887763467],
  ['2018-04-25', 1.73373422783528],
  ['2018-04-26', 2.75659065255974],
  ['2018-04-27', 2.28994717553119],
  ['2018-04-28', 2.53725128082158],
  ['2018-04-29', 0.934876951835308],
  ['2018-04-30', 1.52635360250796],
  ['2018-05-01', 2.83804130848431],
  ['2018-05-02', 2.15089113465118],
  ['2018-05-03', 0.781333830901119],
  ['2018-05-04', 1.86886255983231],
  ['2018-05-05', 0.905172578002086],
  ['2018-05-06', 1.09576241054133],
  ['2018-05-07', 2.20157130709495],
  ['2018-05-08', 0.532948154829681],
  ['2018-05-09', 1.84136678972629],
  ['2018-05-10', 1.32189510300464],
  ['2018-05-11', 2.16134836236018],
  ['2018-05-12', 1.83280439608044],
  ['2018-05-13', 2.47815980220574],
  ['2018-05-14', 1.39313097698675],
  ['2018-05-15', 1.95520405978738],
  ['2018-05-16', 1.93967134291171],
  ['2018-05-17', 2.44061184444057],
  ['2018-05-18', 2.31316805119191],
  ['2018-05-19', 2.35869718765283],
  ['2018-05-20', 1.09860460767082],
  ['2018-05-21', 1.18796199868051],
  ['2018-05-22', 1.37060894447668],
  ['2018-05-23', 2.30785465828871],
  ['2018-05-24', 2.16068378377859],
  ['2018-05-25', 2.93790456243063],
  ['2018-05-26', 2.2385776875113],
  ['2018-05-27', 1.40653983863395],
  ['2018-05-28', 2.97222905821598],
  ['2018-05-29', 2.16033131158151],
  ['2018-05-30', 2.19210997093894],
  ['2018-05-31', 1.83639777130024],
  ['2018-06-01', 2.36381755115675],
  ['2018-06-02', 2.21049842808825],
  ['2018-06-03', 1.52606217091508],
  ['2018-06-04', 0.757947330609168],
  ['2018-06-05', 1.28934018376429],
  ['2018-06-06', 0.555849690092513],
  ['2018-06-07', 0.559746597501694],
  ['2018-06-08', 1.3552704617476],
  ['2018-06-09', 1.8019916342725],
  ['2018-06-10', 1.09818055198289],
  ['2018-06-11', 2.52810279166157],
  ['2018-06-12', 1.2088331610385],
  ['2018-06-13', 2.38449736613689],
  ['2018-06-14', 0.914609262322281],
  ['2018-06-15', 2.9341953533511],
  ['2018-06-16', 2.46322983728468],
  ['2018-06-17', 2.36676546193933],
  ['2018-06-18', 1.86440513493946],
  ['2018-06-19', 0.739154437303197],
  ['2018-06-20', 2.8616351698161],
  ['2018-06-21', 1.54489836722015],
  ['2018-06-22', 2.70434110515599],
  ['2018-06-23', 1.48751395888846],
  ['2018-06-24', 1.60701510756584],
  ['2018-06-25', 0.816665852339538],
  ['2018-06-26', 0.917504701279889],
  ['2018-06-27', 1.84162424828555],
  ['2018-06-28', 1.4974542644667],
  ['2018-06-29', 2.55980331715309],
  ['2018-06-30', 2.24320559936264],
  ['2018-07-01', 0.52663165855366],
  ['2018-07-02', 1.71533556189974],
  ['2018-07-03', 2.88500322421087],
  ['2018-07-04', 0.778819202903079],
  ['2018-07-05', 1.92148433933067],
  ['2018-07-06', 2.31835451602584],
  ['2018-07-07', 0.775752587033434],
  ['2018-07-08', 2.56474024582659],
  ['2018-07-09', 1.1288416775526],
  ['2018-07-10', 0.626436495684442],
  ['2018-07-11', 0.994625806811135],
  ['2018-07-12', 1.87516106631815],
  ['2018-07-13', 0.597391162027794],
  ['2018-07-14', 1.09671367494904],
  ['2018-07-15', 2.69254847570315],
  ['2018-07-16', 2.71626660681533],
  ['2018-07-17', 1.73703830277761],
  ['2018-07-18', 2.21032258506276],
  ['2018-07-19', 1.4316261941353],
  ['2018-07-20', 2.02618369773935],
  ['2018-07-21', 2.4171154768874],
  ['2018-07-22', 2.99486492654403],
  ['2018-07-23', 1.66379318452656],
  ['2018-07-24', 1.71072305423925],
  ['2018-07-25', 2.20992268340557],
  ['2018-07-26', 2.94944106436557],
  ['2018-07-27', 2.04809370493322],
  ['2018-07-28', 2.55526742193482],
  ['2018-07-29', 2.67190380454219],
  ['2018-07-30', 1.5087051160488],
  ['2018-07-31', 0.772751673880527],
  ['2018-08-01', 1.1122383528459],
  ['2018-08-02', 2.97777818709823],
  ['2018-08-03', 1.97606080067495],
  ['2018-08-04', 0.9064469893349],
  ['2018-08-05', 2.96217474341642],
  ['2018-08-06', 2.95870827083693],
  ['2018-08-07', 0.988374118738062],
  ['2018-08-08', 1.5486974881337],
  ['2018-08-09', 2.52853141146675],
  ['2018-08-10', 0.725052530173928],
  ['2018-08-11', 2.77224141677592],
  ['2018-08-12', 2.24936501808083],
  ['2018-08-13', 0.545187496096895],
  ['2018-08-14', 2.55797777247323],
  ['2018-08-15', 1.82449702474778],
  ['2018-08-16', 2.58178831064526],
  ['2018-08-17', 2.70896317598351],
  ['2018-08-18', 1.41754090146841],
  ['2018-08-19', 1.55484197942772],
  ['2018-08-20', 2.95161921790268],
  ['2018-08-21', 2.69092174614924],
  ['2018-08-22', 1.23915998900212],
  ['2018-08-23', 2.73466078048465],
  ['2018-08-24', 1.76881445546199],
  ['2018-08-25', 1.8752243121362],
  ['2018-08-26', 2.05015007638346],
  ['2018-08-27', 1.93818348182927],
  ['2018-08-28', 0.907131038539546],
  ['2018-08-29', 1.62901444777509],
  ['2018-08-30', 2.86873305869874],
  ['2018-08-31', 2.0478558073723],
  ['2018-09-01', 2.37737043219447],
  ['2018-09-02', 2.74246848654788],
  ['2018-09-03', 1.48192444974256],
  ['2018-09-04', 1.68173328789568],
  ['2018-09-05', 2.9691890516539],
  ['2018-09-06', 1.16108840775773],
  ['2018-09-07', 2.45767809044245],
  ['2018-09-08', 0.504959549817432],
  ['2018-09-09', 2.65186472087802],
  ['2018-09-10', 0.929146024988356],
  ['2018-09-11', 2.80989074925059],
  ['2018-09-12', 0.68347619346234],
  ['2018-09-13', 1.79286754419731],
  ['2018-09-14', 2.89173359411529],
  ['2018-09-15', 0.862878368148398],
  ['2018-09-16', 1.42206682163506],
  ['2018-09-17', 2.31266563434193],
  ['2018-09-18', 2.61163189731686],
  ['2018-09-19', 2.69581541302273],
  ['2018-09-20', 2.90892341514024],
  ['2018-09-21', 0.933594539308568],
  ['2018-09-22', 2.33395868675711],
  ['2018-09-23', 2.87293999852354],
  ['2018-09-24', 1.10834549980159],
  ['2018-09-25', 0.802879503938462],
  ['2018-09-26', 1.77341220780797],
  ['2018-09-27', 2.74606844602765],
  ['2018-09-28', 1.58806583233151],
  ['2018-09-29', 2.70657897098705],
  ['2018-09-30', 1.08441113111925],
  ['2018-10-01', 2.96863632296759],
  ['2018-10-02', 2.8792369736909],
  ['2018-10-03', 1.54988231929902],
  ['2018-10-04', 1.57700796857961],
  ['2018-10-05', 1.26107339578999],
  ['2018-10-06', 0.636044777888845],
  ['2018-10-07', 2.0367143062713],
  ['2018-10-08', 0.842008882019835],
  ['2018-10-09', 1.51636665514607],
  ['2018-10-10', 0.656319627442279],
  ['2018-10-11', 2.09616974753195],
  ['2018-10-12', 1.90076594689666],
  ['2018-10-13', 1.81205022251006],
  ['2018-10-14', 2.30392610484656],
  ['2018-10-15', 1.52123360773534],
  ['2018-10-16', 2.59955556230191],
  ['2018-10-17', 2.9315836938201],
  ['2018-10-18', 2.17518602302323],
  ['2018-10-19', 2.97567323341393],
  ['2018-10-20', 2.13770283324849],
  ['2018-10-21', 1.21009857977699],
  ['2018-10-22', 1.5580081847339],
  ['2018-10-23', 1.33486855526966],
  ['2018-10-24', 2.390280852314],
  ['2018-10-25', 0.907950942813506],
  ['2018-10-26', 2.24291923724328],
  ['2018-10-27', 1.61379907100021],
  ['2018-10-28', 2.7001882696213],
  ['2018-10-29', 2.09095914790106],
  ['2018-10-30', 0.812687628060112],
  ['2018-10-31', 1.46743162336104],
  ['2018-11-01', 2.69839477696099],
  ['2018-11-02', 2.78855334353864],
  ['2018-11-03', 2.59218919509121],
  ['2018-11-04', 1.83771625708735],
  ['2018-11-05', 2.44344953164986],
  ['2018-11-06', 1.06722757313423],
  ['2018-11-07', 2.93837285228741],
  ['2018-11-08', 1.14290435518755],
  ['2018-11-09', 2.07378209642664],
  ['2018-11-10', 1.47928964859376],
  ['2018-11-11', 1.35410183437018],
  ['2018-11-12', 2.00510163789781],
  ['2018-11-13', 2.77297974540071],
  ['2018-11-14', 0.804210268979919],
  ['2018-11-15', 0.781591781015267],
  ['2018-11-16', 0.565243205627534],
  ['2018-11-17', 1.48660617582885],
  ['2018-11-18', 1.47778547243176],
  ['2018-11-19', 1.07689302127669],
  ['2018-11-20', 1.12043030888337],
  ['2018-11-21', 1.17293169448782],
  ['2018-11-22', 1.11282651248754],
  ['2018-11-23', 0.62004714815378],
  ['2018-11-24', 1.84205553032219],
  ['2018-11-25', 1.2790239132874],
  ['2018-11-26', 1.62986100343732],
  ['2018-11-27', 2.28606281699807],
  ['2018-11-28', 2.36702611625815],
  ['2018-11-29', 2.7757069089148],
  ['2018-11-30', 2.52015513483689],
  ['2018-12-01', 2.95756695239838],
  ['2018-12-02', 1.48981021271835],
  ['2018-12-03', 1.88199657510807],
  ['2018-12-04', 1.30347126680389],
  ['2018-12-05', 0.641262512809892],
  ['2018-12-06', 0.565318623321796],
  ['2018-12-07', 2.78638017421141],
  ['2018-12-08', 1.41538035638421],
  ['2018-12-09', 0.924274343512589],
  ['2018-12-10', 1.06079879149779],
  ['2018-12-11', 2.46462745848727],
  ['2018-12-12', 2.12951793732174],
  ['2018-12-13', 0.769837189684205],
  ['2018-12-14', 2.23866946400246],
  ['2018-12-15', 0.933316698078746],
  ['2018-12-16', 2.44515940579976],
  ['2018-12-17', 2.08156296981111],
  ['2018-12-18', 1.74439897026253],
  ['2018-12-19', 2.22063798783619],
  ['2018-12-20', 1.30928049884481],
  ['2018-12-21', 2.31081938704469],
  ['2018-12-22', 2.46998794533675],
  ['2018-12-23', 1.0435549573031],
  ['2018-12-24', 1.79051705985409],
  ['2018-12-25', 0.527590851462706],
  ['2018-12-26', 2.12593201949649],
  ['2018-12-27', 2.69945406932566],
  ['2018-12-28', 2.34271126408073],
  ['2018-12-29', 1.56884458185189],
  ['2018-12-30', 1.33649290647844],
  ['2018-12-31', 0.781134696910199],
  ['2019-01-01', 0.972264257493534],
  ['2019-01-02', 1.17616430499282],
  ['2019-01-03', 1.85727674181992],
  ['2019-01-04', 0.612404634049012],
  ['2019-01-05', 2.88870573869734],
  ['2019-01-06', 2.88982803017963],
  ['2019-01-07', 1.41640553550976],
  ['2019-01-08', 2.14193476459926],
  ['2019-01-09', 1.92743976368955],
  ['2019-01-10', 1.11201428845603],
  ['2019-01-11', 2.99947676258396],
  ['2019-01-12', 2.41837646336988],
  ['2019-01-13', 2.99150358655582],
  ['2019-01-14', 2.58185501977879],
  ['2019-01-15', 0.812582287546848],
  ['2019-01-16', 1.12985374487806],
  ['2019-01-17', 2.94440878942264],
  ['2019-01-18', 1.16945118016738],
  ['2019-01-19', 0.939912747355053],
  ['2019-01-20', 1.77248897817469],
  ['2019-01-21', 1.51567741035163],
  ['2019-01-22', 1.67812361439343],
  ['2019-01-23', 2.93409255206213],
  ['2019-01-24', 2.69607264114487],
  ['2019-01-25', 1.06132216587973],
  ['2019-01-26', 2.1438612143872],
  ['2019-01-27', 1.95770384591301],
  ['2019-01-28', 0.835190096912942],
  ['2019-01-29', 2.18960335610774],
  ['2019-01-30', 1.18710716217014],
  ['2019-01-31', 0.699711735979596],
  ['2019-02-01', 2.54821046317722],
  ['2019-02-02', 0.654648055648765],
  ['2019-02-03', 1.00162946059774],
  ['2019-02-04', 1.15106245036483],
  ['2019-02-05', 0.78340961410123],
  ['2019-02-06', 1.26203320459306],
  ['2019-02-07', 1.19037403919007],
  ['2019-02-08', 0.77898471185424],
  ['2019-02-09', 1.16841246212498],
  ['2019-02-10', 2.61813610594403],
  ['2019-02-11', 0.840874709389616],
  ['2019-02-12', 0.572915809536388],
  ['2019-02-13', 1.85938382938449],
  ['2019-02-14', 1.25655834084229],
  ['2019-02-15', 2.21488931511028],
  ['2019-02-16', 1.78418640014937],
  ['2019-02-17', 0.974044336033989],
  ['2019-02-18', 2.31226783582052],
  ['2019-02-19', 2.30764403734363],
  ['2019-02-20', 2.82327979348773],
  ['2019-02-21', 1.2671757870509],
  ['2019-02-22', 1.50865039495493],
  ['2019-02-23', 2.63336062815767],
  ['2019-02-24', 2.5702392906831],
  ['2019-02-25', 1.96024541151228],
  ['2019-02-26', 2.69715754059836],
  ['2019-02-27', 1.89300406862468],
  ['2019-02-28', 2.16989081716265],
  ['2019-03-01', 2.03738304355649],
  ['2019-03-02', 1.41146857750171],
  ['2019-03-03', 0.681782847758357],
  ['2019-03-04', 1.25607105943221],
  ['2019-03-05', 2.65455533084784],
  ['2019-03-06', 2.62966964303146],
  ['2019-03-07', 0.60534926492745],
  ['2019-03-08', 1.91295189509616],
  ['2019-03-09', 2.68815329831562],
  ['2019-03-10', 2.81403126016381],
  ['2019-03-11', 1.81963696087969],
  ['2019-03-12', 2.03591365289147],
  ['2019-03-13', 2.42397338080822],
  ['2019-03-14', 1.10523398432417],
  ['2019-03-15', 2.29569125096805],
  ['2019-03-16', 1.4861780996382],
  ['2019-03-17', 0.908962371506886],
  ['2019-03-18', 0.735642136421792],
  ['2019-03-19', 0.66695056212792],
  ['2019-03-20', 2.44170899863679],
  ['2019-03-21', 2.73978046887238],
  ['2019-03-22', 1.98284599104968],
  ['2019-03-23', 1.46758910542808],
  ['2019-03-24', 2.6854901012361],
  ['2019-03-25', 2.99271793463497],
  ['2019-03-26', 2.22435614074762],
  ['2019-03-27', 1.57767324662363],
  ['2019-03-28', 2.38589772091677],
  ['2019-03-29', 1.63060186301455],
  ['2019-03-30', 1.45694710825801],
  ['2019-03-31', 1.60400145037414],
  ['2019-04-01', 1.84485933182143],
  ['2019-04-02', 1.98456815314168],
  ['2019-04-03', 2.21114104971503],
  ['2019-04-04', 1.6646562184231],
  ['2019-04-05', 1.13563102775475],
  ['2019-04-06', 1.27820516436732],
  ['2019-04-07', 2.06503872789231],
  ['2019-04-08', 2.1327000563651],
  ['2019-04-09', 0.9180959404307],
  ['2019-04-10', 2.09562456485326],
  ['2019-04-11', 2.87258633938625],
  ['2019-04-12', 0.932527438061817],
  ['2019-04-13', 2.19836324978525],
  ['2019-04-14', 2.84752044312171],
  ['2019-04-15', 1.38332702662898],
  ['2019-04-16', 0.804807122410641],
  ['2019-04-17', 0.739485870441868],
  ['2019-04-18', 1.12486761576422],
  ['2019-04-19', 1.42601874843753],
  ['2019-04-20', 0.527792291665649],
  ['2019-04-21', 0.792839386696158],
  ['2019-04-22', 1.91560259604566],
  ['2019-04-23', 2.66300305146836],
  ['2019-04-24', 0.501795279520233],
  ['2019-04-25', 2.04487871587087],
  ['2019-04-26', 1.82987153345388],
  ['2019-04-27', 1.04767817501372],
  ['2019-04-28', 2.43570605931164],
  ['2019-04-29', 1.17882749056192],
  ['2019-04-30', 1.32867373005559],
  ['2019-05-01', 0.840351702024115],
  ['2019-05-02', 1.19550156629391],
  ['2019-05-03', 2.02747817997082],
  ['2019-05-04', 2.23904129119724],
  ['2019-05-05', 1.33302876617726],
  ['2019-05-06', 1.49068245487102],
  ['2019-05-07', 1.86933708116712],
  ['2019-05-08', 2.34747667033288],
  ['2019-05-09', 0.964928805516145],
  ['2019-05-10', 2.59054808682563],
  ['2019-05-11', 1.33862723484583],
  ['2019-05-12', 2.61286679162907],
  ['2019-05-13', 0.786116107985181],
  ['2019-05-14', 0.669791099782696],
  ['2019-05-15', 1.32479448459372],
  ['2019-05-16', 2.65843811605104],
  ['2019-05-17', 1.34142412039159],
  ['2019-05-18', 2.41540200169578],
  ['2019-05-19', 1.57725359712638],
  ['2019-05-20', 2.24841187274772],
  ['2019-05-21', 0.731714356703788],
  ['2019-05-22', 2.03867123474885],
  ['2019-05-23', 1.24059520239417],
  ['2019-05-24', 2.60109302007118],
  ['2019-05-25', 1.19192619954986],
  ['2019-05-26', 2.36844454448821],
  ['2019-05-27', 1.6701491132974],
  ['2019-05-28', 2.87751228938334],
  ['2019-05-29', 1.36202423309796],
  ['2019-05-30', 1.05266919142611],
  ['2019-05-31', 1.38544983157704],
  ['2019-06-01', 2.82079420803857],
  ['2019-06-02', 1.85209961235466],
  ['2019-06-03', 2.31534049952856],
  ['2019-06-04', 0.674918671651909],
  ['2019-06-05', 2.44089214624257],
  ['2019-06-06', 2.74065982111431],
  ['2019-06-07', 2.57450415634607],
  ['2019-06-08', 1.21179547258058],
  ['2019-06-09', 0.911245588994541],
  ['2019-06-10', 2.12369377942257],
  ['2019-06-11', 1.99747908595289],
  ['2019-06-12', 2.86857218664215],
  ['2019-06-13', 0.506113120687675],
  ['2019-06-14', 0.944430731808624],
  ['2019-06-15', 1.26842547650299],
  ['2019-06-16', 1.28097942418048],
  ['2019-06-17', 2.08418861219229],
  ['2019-06-18', 1.73122704620598],
  ['2019-06-19', 2.20090849808226],
  ['2019-06-20', 1.95160122873892],
  ['2019-06-21', 1.48106811025497],
  ['2019-06-22', 1.46920208635808],
  ['2019-06-23', 1.28080603868885],
  ['2019-06-24', 0.641164367445318],
  ['2019-06-25', 0.773389867220588],
  ['2019-06-26', 2.54326364212163],
  ['2019-06-27', 2.92584934509657],
  ['2019-06-28', 0.695958081374245],
  ['2019-06-29', 1.99635782736064],
  ['2019-06-30', 2.58063005088556],
  ['2019-07-01', 2.62899792664609],
  ['2019-07-02', 0.515471129545754],
  ['2019-07-03', 2.57767965951416],
  ['2019-07-04', 2.00678616441151],
  ['2019-07-05', 0.801568747270647],
  ['2019-07-06', 2.23391954239838],
  ['2019-07-07', 2.90409659508354],
  ['2019-07-08', 2.96659826534226],
  ['2019-07-09', 2.08997908029781],
  ['2019-07-10', 2.37437251356539],
  ['2019-07-11', 1.16827056588947],
  ['2019-07-12', 1.12436969376677],
  ['2019-07-13', 1.00935542404631],
  ['2019-07-14', 2.52070724839837],
  ['2019-07-15', 2.75864207878046],
  ['2019-07-16', 0.954021041552157],
  ['2019-07-17', 0.679449785861598],
  ['2019-07-18', 0.920127910622293],
  ['2019-07-19', 1.36712403393933],
  ['2019-07-20', 1.43995639822816],
  ['2019-07-21', 1.35152167969279],
  ['2019-07-22', 1.26781316224412],
  ['2019-07-23', 1.46957407671863],
  ['2019-07-24', 1.83518192733163],
  ['2019-07-25', 1.34027231162182],
  ['2019-07-26', 2.14162649192147],
  ['2019-07-27', 2.9486040740957],
  ['2019-07-28', 2.8030171595226],
  ['2019-07-29', 2.63860883329988],
  ['2019-07-30', 2.63135140660034],
  ['2019-07-31', 0.91491531782023],
  ['2019-08-01', 2.45080125826872],
  ['2019-08-02', 0.728431996586332],
  ['2019-08-03', 1.55702410270641],
  ['2019-08-04', 2.37418249832501],
  ['2019-08-05', 0.668173038525363],
  ['2019-08-06', 2.94615454185337],
  ['2019-08-07', 1.49802977252522],
  ['2019-08-08', 1.4642681703463],
  ['2019-08-09', 2.18986153734735],
  ['2019-08-10', 0.785277353610293],
  ['2019-08-11', 1.13900119464337],
  ['2019-08-12', 1.01617382847934],
  ['2019-08-13', 1.38208856979619],
  ['2019-08-14', 2.15214786385602],
  ['2019-08-15', 1.31970198029374],
  ['2019-08-16', 1.03047715912792],
  ['2019-08-17', 1.20778763661024],
  ['2019-08-18', 1.00343431947312],
  ['2019-08-19', 2.72496769987523],
  ['2019-08-20', 0.524730894189387],
  ['2019-08-21', 2.84498122143844],
  ['2019-08-22', 2.74938841015748],
  ['2019-08-23', 1.74020898135429],
  ['2019-08-24', 2.47271067119085],
  ['2019-08-25', 0.813814456040564],
  ['2019-08-26', 2.13930507516384],
  ['2019-08-27', 0.682936659813512],
  ['2019-08-28', 1.5913305752139],
  ['2019-08-29', 1.40552418557947],
  ['2019-08-30', 1.28767794623472],
  ['2019-08-31', 2.28211744095858],
  ['2019-09-01', 2.8587134830838],
  ['2019-09-02', 0.569128259585485],
  ['2019-09-03', 1.57790665543857],
  ['2019-09-04', 1.41951718181704],
  ['2019-09-05', 0.820588522518419],
  ['2019-09-06', 2.1481592402794],
  ['2019-09-07', 1.95290519734097],
  ['2019-09-08', 2.19423583037003],
  ['2019-09-09', 1.21790800038646],
  ['2019-09-10', 2.88107551747126],
  ['2019-09-11', 0.625698642738225],
  ['2019-09-12', 1.1046260786744],
  ['2019-09-13', 1.02595845259229],
  ['2019-09-14', 0.582671065975735],
  ['2019-09-15', 2.48942472063009],
  ['2019-09-16', 1.36944822584732],
  ['2019-09-17', 1.68935415227023],
  ['2019-09-18', 2.71442905581559],
  ['2019-09-19', 1.44210046610185],
  ['2019-09-20', 2.71955672418314],
  ['2019-09-21', 1.04688404180121],
  ['2019-09-22', 1.7977735674984],
  ['2019-09-23', 1.77991967399867],
  ['2019-09-24', 1.8831666970886],
  ['2019-09-25', 1.91883944386935],
  ['2019-09-26', 1.33670247977417],
  ['2019-09-27', 2.65757027050827],
  ['2019-09-28', 2.25577957838636],
  ['2019-09-29', 1.61202925727695],
  ['2019-09-30', 1.39587245593375],
  ['2019-10-01', 1.66351774817307],
  ['2019-10-02', 0.856477640972949],
  ['2019-10-03', 0.626621083867525],
  ['2019-10-04', 2.4467330326223],
  ['2019-10-05', 1.86911519894324],
  ['2019-10-06', 1.22496985805739],
  ['2019-10-07', 2.93263266951675],
  ['2019-10-08', 2.94549663028371],
  ['2019-10-09', 2.54220265011202],
  ['2019-10-10', 2.1980483044716],
  ['2019-10-11', 2.12698784358262],
  ['2019-10-12', 2.64676142293634],
  ['2019-10-13', 1.86760459913992],
  ['2019-10-14', 0.750144693909475],
  ['2019-10-15', 0.641470705300503],
  ['2019-10-16', 0.775166836864476],
  ['2019-10-17', 1.17538848022879],
  ['2019-10-18', 2.65603409821246],
  ['2019-10-19', 0.861321716593626],
  ['2019-10-20', 1.2965512966297],
  ['2019-10-21', 0.560308219914022],
  ['2019-10-22', 1.86970242742773],
  ['2019-10-23', 0.954505286940649],
  ['2019-10-24', 0.760671077075375],
  ['2019-10-25', 2.91275434012054],
  ['2019-10-26', 0.79654472902243],
  ['2019-10-27', 2.33473737984944],
  ['2019-10-28', 0.981211591875677],
  ['2019-10-29', 2.56552096002706],
  ['2019-10-30', 0.633457324837683],
  ['2019-10-31', 1.46932907090633],
  ['2019-11-01', 1.66059499410145],
  ['2019-11-02', 1.31864260460938],
  ['2019-11-03', 1.02983614330219],
  ['2019-11-04', 1.71810530546244],
  ['2019-11-05', 1.09970517962518],
  ['2019-11-06', 1.75868432366365],
  ['2019-11-07', 1.39769498354848],
  ['2019-11-08', 2.11441626228314],
  ['2019-11-09', 0.85538378591023],
  ['2019-11-10', 2.64897956978074],
  ['2019-11-11', 1.37261196545759],
  ['2019-11-12', 2.8857291855159],
  ['2019-11-13', 2.58303342998965],
  ['2019-11-14', 2.34793114594741],
  ['2019-11-15', 1.82434816264912],
  ['2019-11-16', 2.79107072681969],
  ['2019-11-17', 2.19006155073187],
  ['2019-11-18', 1.13445324232085],
  ['2019-11-19', 1.38594387263745],
  ['2019-11-20', 2.93029292407531],
  ['2019-11-21', 0.98828417162869],
  ['2019-11-22', 1.29989867283606],
  ['2019-11-23', 1.92755439868275],
  ['2019-11-24', 0.770433207091242],
  ['2019-11-25', 2.44357254357866],
  ['2019-11-26', 2.81234541875838],
  ['2019-11-27', 0.619277254296325],
  ['2019-11-28', 2.728801518193],
  ['2019-11-29', 0.693356018908371],
  ['2019-11-30', 1.82014491561765],
  ['2019-12-01', 2.19499219387186],
  ['2019-12-02', 2.40328907418826],
  ['2019-12-03', 1.694704641123],
  ['2019-12-04', 2.55236472689365],
  ['2019-12-05', 0.831517357523907],
  ['2019-12-06', 0.567782822811357],
  ['2019-12-07', 0.726824455306682],
  ['2019-12-08', 0.849957616614793],
  ['2019-12-09', 2.01327096737285],
  ['2019-12-10', 1.86996817913305],
  ['2019-12-11', 0.697792749213894],
  ['2019-12-12', 2.30618250503534],
  ['2019-12-13', 2.46310936884515],
  ['2019-12-14', 1.81036804689031],
  ['2019-12-15', 2.59240564784909],
  ['2019-12-16', 1.79437194502781],
  ['2019-12-17', 2.8540009088338],
  ['2019-12-18', 2.64865726689011],
  ['2019-12-19', 0.727573675219104],
  ['2019-12-20', 1.47131990919042],
  ['2019-12-21', 0.514670715865546],
  ['2019-12-22', 0.565759522807392],
  ['2019-12-23', 0.557487340682361],
  ['2019-12-24', 1.11790185506975],
  ['2019-12-25', 2.22981378814002],
  ['2019-12-26', 1.97248749279604],
  ['2019-12-27', 0.982935696971552],
  ['2019-12-28', 0.780802395318604],
  ['2019-12-29', 0.670274963441583],
  ['2019-12-30', 2.38017647068397],
  ['2019-12-31', 1.57017377809915],
  ['2020-01-01', 2.03032210581612],
  ['2020-01-02', 0.68654075591636],
  ['2020-01-03', 0.902648721712058],
  ['2020-01-04', 1.93919895558495],
  ['2020-01-05', 1.41609828337942],
  ['2020-01-06', 1.52204552505759],
  ['2020-01-07', 2.83452540232387],
  ['2020-01-08', 1.77279570324207],
  ['2020-01-09', 1.06138644302342],
];
