const resource = "/challenges/";

export default class Challenges {
  constructor(api) {
    this.api = api;
  }

  async get(id, formEdit = false) {
    let res = await this.api.get(`${resource}${id}`);

    if (formEdit) {
      res.data["pelm_utilities"] = res.data["pelm_utilities"].map((o) => o.id);
    }
    return res;
  }

  getAll() {
    return this.api.get(`${resource}`, {data: {}});
  }

  getAllWithCorporateCode() {
    return this.api.get(`${resource}?my_challenge`, {data: {}});
  }

  getAllWithCorporateCode() {
    return this.api.get(`${resource}?my_challenge=true`);
  }

  async getLeaderBoard(id) {
    try {
      return await this.api.get(`${resource}${id}/leaderboard`);
    } catch (error) {
      return null;
    }
  }

  submitForReview(id) {
    return this.api.get(`${resource}${id}/submit`);
  }

  launch(id) {
    return this.api.get(`${resource}${id}/launch`);
  }

  async getMyChallenges() {
    try {
      return await this.api.get(`${resource}mychallenges`);
    } catch (error) {
      console.log("getMyChallenges", error);
      return null;
    }
  }

  create(payload) {
    return this.api.post(`${resource}`, payload);
  }

  async update(id, payload) {
    let res = await this.api.put(`${resource}${id}`, payload);

    res.data["pelm_utilities"] = res.data["pelm_utilities"].map((o) => o.id);

    return res;
  }

  uploadImage(id, payload) {
    return this.api.post(`${resource}${id}/image`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return this.api.delete(`${resource}${id}`);
  }

  submitPassword(id, payload) {
    return this.api.post(`${resource}${id}`, payload);
  }
}
