const resource = '/team/';

export default class Teams {
  constructor(api) {
    this.api = api;
  }

  getAll() {
    return this.api.get(`${resource}`);
  }

  get(teamId) {
    return this.api.get(`${resource}${teamId}`);
  }

  create() {
    return this.api.post(`${resource}`);
  }

  update(teamId, payload) {
    return this.api.put(`${resource}${teamId}`, payload);
  }

  disband(id) {
    return this.api.delete(`${resource}${id}`);
  }

  invite(teamId, payload) {
    if (TESTING) {
      return this.api.post(`/testing/team/${teamId}/invite_test_user`, payload);
    } else {
      return this.api.post(`${resource}${teamId}/invite`, payload);
    }
  }

  leave(teamId) {
    return this.api.get(`${resource}${teamId}/leave`);
  }

  addMeter(teamId, meterId) {
    return this.api.get(`${resource}${teamId}/add/${meterId}`);
  }

  removeMeter(teamId, meterId) {
    return this.api.get(`${resource}${teamId}/remove/${meterId}`);
  }
}
