import { mutationTypes, actionTypes } from "@/store/types";

export const state = () => ({
  challenges: [],
  challengesCorporate: [],
  isLoggedIn: false,
  userData: {},
  mobileHeaderTitle: 'Meterleader',
  user: {
    profile: {},
    teams: [],
    utilityAccounts: [],
    isConnected: true,
  },
  comments: {
    comments: [],
    new_comment_count: null,
  },
});

export const mutations = {
  [mutationTypes.SET_CHALLENGES](state, challenges) {
    state.challenges = challenges;
  },
  [mutationTypes.SET_CHALLENGES_CORPORATE](state, challenges) {
    state.challengesCorporate = challenges;
  },
  [mutationTypes.SET_TEAMS](state, teams) {
    state.user.teams = teams;
  },
  [mutationTypes.SET_UTILITY_ACCOUNTS](state, utilityAccounts) {
    state.user.utilityAccounts = utilityAccounts;

    let hasConnection = false;
    for (const account of utilityAccounts) {
      hasConnection |= account.status > 0;
    }
    state.user.isConnected = hasConnection;
  },
  [mutationTypes.SET_USER_PROFILE](state, profile) {
    state.user.profile = profile;
  },
  [mutationTypes.SET_COMMENTS](state, comments) {
    state.comments = comments;
  },
  [mutationTypes.CLEAR_DISCUSSION_NOTIFICATION](state) {
    state.comments.new_comment_count = null;
  },
};

export const actions = {
  async setMobileTitle({ state }, title) {
   state.mobileHeaderTitle = title
  },
  async [actionTypes.GET_CHALLENGES]({ commit }) {
    const { data } = await this.$repo.challenges.getAll();
    commit(mutationTypes.SET_CHALLENGES, data);
  },
  async [actionTypes.GET_CHALLENGES_CORPORATE]({ commit }) {
    const { data } = await this.$repo.challenges.getAllWithCorporateCode();
    commit(mutationTypes.SET_CHALLENGES_CORPORATE, data);
  },
  async [actionTypes.GET_TEAMS]({ commit }) {
    const { data } = await this.$repo.teams.getAll();
    commit(mutationTypes.SET_TEAMS, data);
  },
  async [actionTypes.GET_UTILITY_ACCOUNTS]({ commit }) {
    const { data } = await this.$repo.accounts.getAll();
    commit(mutationTypes.SET_UTILITY_ACCOUNTS, data);
  },
  async [actionTypes.GET_USER_PROFILE]({ commit }) {
    const { data } = await this.$repo.user.get();
    commit(mutationTypes.SET_USER_PROFILE, data.profile);
  },
  async [actionTypes.GET_COMMENTS]({ commit }, challengeId) {
    const { data } = await this.$api.get(`/challenges/${challengeId}/comments`);
    commit(mutationTypes.SET_COMMENTS, data);
  },
};
