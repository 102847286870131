export default function ({ $auth, redirect, error }) {
    if (!$auth.loggedIn) {
        redirect('/login')
    } else {
        let isAdmin = $auth.$state.user.admin
        if (!isAdmin) {
            error({ statusCode: 404, message: 'Page not found' })
        }

    }
}
