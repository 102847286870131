export const challenges = [
  // 0 - Upcoming
  [
    {
      authorized: true,
      description: 'Bay Area Energy Savings Challenge',
      entered: false,
      id: 3,
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-1-tile.png',
      is_active: true,
      name: 'Bay Area Energy Savings Challenge',
      savings_goal: 1000,
      savings_kwh: 0,
      slug: 'test-challenge',
      sponsor_name: 'Sponsor name',
      sponsor_website: 'https://www.sponsor.com',
      start_date: '2018-12-01 07:00 +0000',
      end_date: '2019-03-01 07:00 +0000',
      image_banner_url: '/assets/images/challenge-1-banner.png',
      status: 5,
    },
  ],
  // 1 - Current
  [
    {
      authorized: true,
      description: 'Description here',
      end_date: '2020-03-01 07:00 +0000',
      entered: false,
      id: 1,
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-2-tile.png',
      is_active: true,
      name: 'Current challenge',
      savings_goal: 1000,
      savings_kwh: 0,
      slug: 'test-challenge',
      sponsor_name: 'Sponsor name',
      sponsor_website: 'https://www.sponsor.com',
      start_date: '2019-12-01 07:00 +0000',
      image_banner_url: '/assets/images/challenge-2-banner.png',
    },
    {
      authorized: true,
      description: 'Description here',
      end_date: '2020-03-01 07:00 +0000',
      entered: false,
      id: 1,
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-2-tile.png',
      is_active: true,
      name: 'Current challenge',
      savings_goal: 1000,
      savings_kwh: 0,
      slug: 'test-challenge',
      sponsor_name: 'Sponsor name',
      sponsor_website: 'https://www.sponsor.com',
      start_date: '2019-12-01 07:00 +0000',
      image_banner_url: '/assets/images/challenge-2-banner.png',
    },
    {
      authorized: true,
      description: 'Description here',
      end_date: '2020-03-01 07:00 +0000',
      entered: false,
      id: 1,
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-2-tile.png',
      is_active: true,
      name: 'Current challenge',
      savings_goal: 1000,
      savings_kwh: 0,
      slug: 'test-challenge',
      sponsor_name: 'Sponsor name',
      sponsor_website: 'https://www.sponsor.com',
      start_date: '2019-12-01 07:00 +0000',
      image_banner_url: '/assets/images/challenge-2-banner.png',
    },
  ],
  // 2 - Past
  [
    {
      authorized: true,
      description: 'Description here',
      end_date: '2020-03-01 07:00 +0000',
      entered: false,
      id: 2,
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-4-tile.png',
      is_active: true,
      name: 'Upcoming challenge',
      savings_goal: 1000,
      savings_kwh: 0,
      slug: 'test-challenge',
      sponsor_name: 'Sponsor name',
      sponsor_website: 'https://www.sponsor.com',
      start_date: '2019-12-01 07:00 +0000',
      image_banner_url: '/assets/images/challenge-4-banner.png',
    },
  ],
  // 3 - User created challenges
  [
    {
      authorized: true,
      clean_description: 'Description here',
      created_dtm: 'Tue, 07 Jan 2020 18:51:57 -0000',
      current_participants: 0,
      description: 'Description here',
      description_collective_goal: 'Save the world!',
      description_eligibility: 'Only for me!',
      description_individual_goal: 'Save lots!',
      description_prizes: 'Shiny prize!',
      description_rules_url: 'http://www.rules.com',
      end_date: '2020-03-01 07:00 +0000',
      id: 1,
      image_banner_url: '/assets/images/challenge-2-banner.png',
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-2-tile.png',
      is_active: true,
      max_participants: 100,
      name: 'Test challenge',
      savings_goal: 1000,
      savings_kwh: 0,
      savings_updated_dtm: 'Tue, 07 Jan 2020 18:51:57 -0000',
      slug: 'test-challenge',
      sponsor_facebook: 'https://www.facebook.com/sponsor',
      sponsor_name: 'Sponsor name',
      sponsor_twitter: 'https://www.twitter.com/sponsor',
      sponsor_website: 'https://www.sponsor.com',
      start_date: '2019-12-01 07:00 +0000',
    },
    {
      authorized: true,
      clean_description: 'Description here',
      created_dtm: 'Tue, 07 Jan 2020 21:20:01 -0000',
      current_participants: 0,
      description: 'Description here',
      description_collective_goal: 'Save the world!',
      description_eligibility: 'Only for me!',
      description_individual_goal: 'Save lots!',
      description_prizes: 'Shiny prize!',
      description_rules_url: 'http://www.rules.com',
      end_date: '2020-03-01 07:00 +0000',
      id: 2,
      image_banner_url: '~/assets/images/challenge-2-banner.png',
      image_creator_url: '~/assets/images/challenge-2-logo.png',
      image_tile_url: '~/assets/images/challenge-2-tile.png',
      is_active: true,
      max_participants: 100,
      name: 'Test challenge',
      savings_goal: 1000,
      savings_kwh: 0,
      savings_updated_dtm: 'Tue, 07 Jan 2020 21:20:01 -0000',
      slug: 'test-challenge',
      sponsor_facebook: 'https://www.facebook.com/sponsor',
      sponsor_name: 'Sponsor name',
      sponsor_twitter: 'https://www.twitter.com/sponsor',
      sponsor_website: 'https://www.sponsor.com',
      start_date: '2019-12-01 07:00 +0000',
    },
    {
      authorized: true,
      clean_description: 'Bay Area Energy Savings Challenge',
      created_dtm: 'Tue, 07 Jan 2020 21:21:07 -0000',
      current_participants: 0,
      description: 'Description here',
      description_collective_goal: 'Save the world!',
      description_eligibility: 'Only for me!',
      description_individual_goal: 'Save lots!',
      description_prizes: 'Shiny prize!',
      description_rules_url: 'http://www.rules.com',
      end_date: '2020-03-01 07:00 +0000',
      id: 3,
      image_banner_url: '~/assets/images/challenge-2-banner.png',
      image_creator_url: '~/assets/images/challenge-2-logo.png',
      image_tile_url: '~/assets/images/challenge-2-tile.png',
      is_active: true,
      max_participants: 100,
      name: 'Bay Area Energy Savings Challenge',
      savings_goal: 1000,
      savings_kwh: 0,
      savings_updated_dtm: 'Tue, 07 Jan 2020 21:21:07 -0000',
      slug: 'test-challenge',
      sponsor_facebook: 'https://www.facebook.com/sponsor',
      sponsor_name: 'Sponsor name',
      sponsor_twitter: 'https://www.twitter.com/sponsor',
      sponsor_website: 'https://www.sponsor.com',
      start_date: '2019-12-01 07:00 +0000',
    },
  ],
];

export const challenge1 = {
  id: 1,
  name: 'Test challenge',
  slug: 'test-challenge',
  created_dtm: 'Tue, 07 Jan 2020 18:51:57 -0000',
  is_active: true,
  image_banner_url: '/assets/images/challenge-2-banner.png',
  image_tile_url: '/assets/images/challenge-2-tile.png',
  image_creator_url: '/assets/images/challenge-2-logo.png',
  start_date: '2019-12-01 07:00 +0000',
  end_date: '2020-03-01 07:00 +0000',
  sponsor_name: 'Sponsor name',
  sponsor_website: 'https://www.sponsor.com',
  sponsor_facebook: 'https://www.facebook.com/sponsor',
  sponsor_twitter: 'https://www.twitter.com/sponsor',
  savings_goal: 1000,
  savings_kwh: 100,
  savings_updated_dtm: 'Tue, 07 Jan 2020 18:51:57 -0000',
  description: 'Description here',
  clean_description: 'Description here',
  description_prizes:
    'One participant will be selected to win a $300 Visa gift card. And if the collective 7,000 kilowatt-hour (kWh) energy savings goal is met, two additional winners will each receive a $100 Visa Gift Card. So invite your friends to join! Winner will be selected randomly from the total pool of entries. Participants get one entry for every 1% of electricity they save. Saving more electricity increases your chances of winning a prize.',
  description_eligibility:
    '1. Must be a primary account holder of an active residential Pacific Gas & Electric (PG&E) electric account\n' +
    "2. Only participants who have been living at the home address of the associated PG&E account for at least 1 year and therefore have at least 1 year's worth of electricity data, are eligible to win the reward. Home address must be primary location of residence.\n" +
    "3. Participants with less than 1 year's worth of electricity data are still eligible to participate in the challenge, but will not be eligible to win the reward.",
  description_individual_goal:
    'Reduce your kWh electricity usage by as much as you can (as compared to your usage from 4/22/2018-6/30/2018). Try to aim for 5% energy savings.',
  description_collective_goal:
    'Achieve a total of 7,000 kWh in electricity savings as a collective group. This is enough electricity to power an average California home for a year!',
  description_rules_url: 'http://www.rules.com',
  max_participants: 100,
  current_participants: 4,
  authorized: true,
  status: 1,
};

export const challenge2 = {
  id: 2,
  name: '100% LED Bulb Challenge',
  slug: 'bay-area-energy-savings-challenge',
  created_dtm: 'Tue, 07 Jan 2020 18:51:57 -0000',
  is_active: true,
  image_banner_url: '/assets/images/challenge-4-banner.png',
  image_tile_url: '/assets/images/challenge-4-tile.png',
  image_creator_url: '/assets/images/challenge-2-logo.png',
  start_date: '2019-12-01 07:00 +0000',
  end_date: '2020-03-01 07:00 +0000',
  sponsor_name: 'MeterLeader',
  sponsor_website: 'https://www.sponsor.com',
  sponsor_facebook: 'https://www.facebook.com/sponsor',
  sponsor_twitter: 'https://www.twitter.com/sponsor',
  savings_goal: 1000,
  savings_kwh: 100,
  savings_updated_dtm: 'Tue, 07 Jan 2020 18:51:57 -0000',
  description:
    'MeterLeader is challenging all Bay Area residents that have active residential Pacific Gas & Electric (PG&E) electric accounts to reduce their kilowatt-hour (kWh) electricity usage during the challenge period Dec. 1, 2018 - Mar. 1, 2019, as compared to their electricity usage during Dec. 1, 2017 - Mar. 1, 2018. If the collective goal of 7,000 kWh energy savings is met, MeterLeader will donate $500 to one local Bay Area environmental non-profit. The non-profits will be nominated and selected based on surveys sent to Challenge participants via email during the Challenge duration period.',
  clean_description:
    'MeterLeader is challenging all Bay Area residents that have active residential Pacific Gas & Electric (PG&E) electric accounts to reduce their kilowatt-hour (kWh) electricity usage during the challenge period Dec. 1, 2018 - Mar. 1, 2019, as compared to their electricity usage during Dec. 1, 2017 - Mar. 1, 2018. If the collective goal of 7,000 kWh energy savings is met, MeterLeader will donate $500 to one local Bay Area environmental non-profit. The non-profits will be nominated and selected based on surveys sent to Challenge participants via email during the Challenge duration period.',
  description_prizes:
    'If the collective goal of 7,000 total kWh in energy savings is met, then MeterLeader will donate $500 to one local Bay Area environmental non-profit chosen by Challenge participants. Survey to nominate and select a winner will be sent out via email during the Challenge duration period.',
  description_eligibility: `1. Must be a primary account holder of an active residential PG&E electric utility account
2. Must have been living at the home address of the associated PG&E account for at least one year and therefore have at least 1 year's worth of electricity data.
3. Challenge capped at 200 total participants on a first come first serve basis.`,
  description_individual_goal:
    'Reduce your kWh electricity usage by at least 5% (as compared to your usage from 12/1/2017-3/1/2018)',
  description_collective_goal:
    'Achieve a total of 7,000 kWh in electricity savings as a collective group',
  description_rules_url: 'http://www.rules.com',
  max_participants: 100,
  current_participants: 0,
  authorized: true,
};

export const challenge3 = {
  id: 3,
  name: 'Bay Area Energy Savings Challenge',
  slug: 'bay-area-energy-savings-challenge',
  created_dtm: '2018-11-01 07:00 +0000',
  is_active: true,
  image_banner_url: '/assets/images/challenge-1-banner.png',
  image_tile_url: '/assets/images/challenge-1-tile.png',
  image_creator_url: '/assets/images/challenge-2-logo.png',
  start_date: '2018-12-01 07:00 +0000',
  end_date: '2019-03-01 07:00 +0000',
  sponsor_name: 'MeterLeader',
  sponsor_website: 'https://www.sponsor.com',
  sponsor_facebook: 'https://www.facebook.com/sponsor',
  sponsor_twitter: 'https://www.twitter.com/sponsor',
  savings_goal: 1000,
  savings_kwh: 100,
  savings_updated_dtm: 'Tue, 07 Jan 2020 18:51:57 -0000',
  description:
    'MeterLeader is challenging all Bay Area residents that have active residential Pacific Gas & Electric (PG&E) electric accounts to reduce their kilowatt-hour (kWh) electricity usage during the challenge period Dec. 1, 2018 - Mar. 1, 2019, as compared to their electricity usage during Dec. 1, 2017 - Mar. 1, 2018. If the collective goal of 7,000 kWh energy savings is met, MeterLeader will donate $500 to one local Bay Area environmental non-profit. The non-profits will be nominated and selected based on surveys sent to Challenge participants via email during the Challenge duration period.',
  clean_description:
    'MeterLeader is challenging all Bay Area residents that have active residential Pacific Gas & Electric (PG&E) electric accounts to reduce their kilowatt-hour (kWh) electricity usage during the challenge period Dec. 1, 2018 - Mar. 1, 2019, as compared to their electricity usage during Dec. 1, 2017 - Mar. 1, 2018. If the collective goal of 7,000 kWh energy savings is met, MeterLeader will donate $500 to one local Bay Area environmental non-profit. The non-profits will be nominated and selected based on surveys sent to Challenge participants via email during the Challenge duration period.',
  description_prizes:
    'If the collective goal of 7,000 total kWh in energy savings is met, then MeterLeader will donate $500 to one local Bay Area environmental non-profit chosen by Challenge participants. Survey to nominate and select a winner will be sent out via email during the Challenge duration period.',
  description_eligibility:
    '1. Must be a primary account holder of an active residential PG&E electric utility account\n' +
    "2. Must have been living at the home address of the associated PG&E account for at least one year and therefore have at least 1 year's worth of electricity data.\n" +
    '3. Challenge capped at 200 total participants on a first come first serve basis.',
  description_individual_goal:
    'Reduce your kWh electricity usage by at least 5% (as compared to your usage from 12/1/2017-3/1/2018)',
  description_collective_goal:
    'Achieve a total of 7,000 kWh in electricity savings as a collective group',
  description_rules_url: 'http://www.rules.com',
  max_participants: 100,
  current_participants: 0,
  authorized: true,
};

export const mychallenges = [
  // 0 - Upcoming
  [],
  // 1 - Current
  [
    {
      authorized: true,
      description: 'Description here',
      end_date: '2020-03-01 07:00 +0000',
      entered: false,
      id: 1,
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-2-tile.png',
      is_active: true,
      name: 'Draft challenge',
      savings_goal: 1000,
      savings_kwh: 0,
      slug: 'test-challenge',
      sponsor_name: 'Sponsor name',
      sponsor_website: 'https://www.sponsor.com',
      start_date: '2019-12-01 07:00 +0000',
      image_banner_url: '/assets/images/challenge-2-banner.png',
    },
    {
      authorized: true,
      description: 'Description here',
      end_date: '2020-03-01 07:00 +0000',
      entered: false,
      id: 1,
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-2-tile.png',
      is_active: true,
      name: 'Current challenge',
      savings_goal: 1000,
      savings_kwh: 0,
      slug: 'test-challenge',
      sponsor_name: 'Sponsor name',
      sponsor_website: 'https://www.sponsor.com',
      start_date: '2019-12-01 07:00 +0000',
      image_banner_url: '/assets/images/challenge-2-banner.png',
    },
    {
      authorized: true,
      description: 'Description here',
      end_date: '2020-03-01 07:00 +0000',
      entered: false,
      id: 1,
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-2-tile.png',
      is_active: true,
      name: 'Current challenge',
      savings_goal: 1000,
      savings_kwh: 0,
      slug: 'test-challenge',
      sponsor_name: 'Sponsor name',
      sponsor_website: 'https://www.sponsor.com',
      start_date: '2019-12-01 07:00 +0000',
      image_banner_url: '/assets/images/challenge-2-banner.png',
    },
  ],
  // 2 - Past
  [
    {
      authorized: false,
      clean_description: '[Description]',
      created_dtm: 'Sat, 11 Jan 2020 22:25:08 -0000',
      current_participants: 1,
      description: '[Description]',
      description_collective_goal: '[Collective goal]',
      description_eligibility: '[Eligibility description]',
      description_individual_goal: '[Individual goal]',
      description_prizes: '[Pride description]',
      description_rules_url: '[Rules]',
      end_date: '2020-06-01 07:00 +0000',
      id: 1,
      image_banner_url: '',
      image_creator_url: '',
      image_tile_url: '',
      is_active: true,
      max_participants: 200,
      name: "Shant Bayanduryan's Challenge",
      savings_goal: 0,
      savings_kwh: 0,
      savings_updated_dtm: null,
      slug: 'shant-bayanduryan-s-challenge',
      sponsor_facebook: '[Sponsor facebook]',
      sponsor_name: 'Shant Bayanduryan',
      sponsor_twitter: '[Sponsor twitter]',
      sponsor_website: '[Sponsor website]',
      start_date: '2020-03-01 07:00 +0000',
    },
  ],
  // 3 - User created challenges
  [
    {
      authorized: false,
      clean_description: '[Description]',
      created_dtm: 'Sat, 11 Jan 2020 22:25:08 -0000',
      current_participants: 1,
      description: '[Description]',
      description_collective_goal: '[Collective goal]',
      description_eligibility: '[Eligibility description]',
      description_individual_goal: '[Individual goal]',
      description_prizes: '[Pride description]',
      description_rules_url: '[Rules]',
      end_date: '2020-06-01 07:00 +0000',
      id: 1,
      image_banner_url: '/assets/images/challenge-1-banner.png',
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-1-tile.png',
      is_active: true,
      max_participants: 200,
      name: 'Draft Challenge',
      savings_goal: 0,
      savings_kwh: 0,
      savings_updated_dtm: null,
      slug: 'shant-bayanduryan-s-challenge',
      sponsor_facebook: '[Sponsor facebook]',
      sponsor_name: 'Shant Bayanduryan',
      sponsor_twitter: '[Sponsor twitter]',
      sponsor_website: '[Sponsor website]',
      start_date: '2020-03-01 07:00 +0000',
      status: 0,
    },
    {
      authorized: false,
      clean_description: '[Description]',
      created_dtm: 'Sat, 11 Jan 2020 22:25:08 -0000',
      current_participants: 1,
      description: '[Description]',
      description_collective_goal: '[Collective goal]',
      description_eligibility: '[Eligibility description]',
      description_individual_goal: '[Individual goal]',
      description_prizes: '[Pride description]',
      description_rules_url: '[Rules]',
      end_date: '2020-06-01 07:00 +0000',
      id: 1,
      image_banner_url: '/assets/images/challenge-2-banner.png',
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-2-tile.png',
      is_active: true,
      max_participants: 200,
      name: 'Submitted Challenge',
      savings_goal: 0,
      savings_kwh: 0,
      savings_updated_dtm: null,
      slug: 'shant-bayanduryan-s-challenge',
      sponsor_facebook: '[Sponsor facebook]',
      sponsor_name: 'Shant Bayanduryan',
      sponsor_twitter: '[Sponsor twitter]',
      sponsor_website: '[Sponsor website]',
      start_date: '2020-03-01 07:00 +0000',
      status: 1,
    },
    {
      authorized: false,
      clean_description: '[Description]',
      created_dtm: 'Sat, 11 Jan 2020 22:25:08 -0000',
      current_participants: 1,
      description: '[Description]',
      description_collective_goal: '[Collective goal]',
      description_eligibility: '[Eligibility description]',
      description_individual_goal: '[Individual goal]',
      description_prizes: '[Pride description]',
      description_rules_url: '[Rules]',
      end_date: '2020-06-01 07:00 +0000',
      id: 1,
      image_banner_url: '/assets/images/challenge-2-banner.png',
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-2-tile.png',
      is_active: true,
      max_participants: 200,
      name: 'Returned Challenge',
      savings_goal: 0,
      savings_kwh: 0,
      savings_updated_dtm: null,
      slug: 'shant-bayanduryan-s-challenge',
      sponsor_facebook: '[Sponsor facebook]',
      sponsor_name: 'Shant Bayanduryan',
      sponsor_twitter: '[Sponsor twitter]',
      sponsor_website: '[Sponsor website]',
      start_date: '2020-03-01 07:00 +0000',
      status: 2,
    },
    {
      authorized: false,
      clean_description: '[Description]',
      created_dtm: 'Sat, 11 Jan 2020 22:25:08 -0000',
      current_participants: 1,
      description: '[Description]',
      description_collective_goal: '[Collective goal]',
      description_eligibility: '[Eligibility description]',
      description_individual_goal: '[Individual goal]',
      description_prizes: '[Pride description]',
      description_rules_url: '[Rules]',
      end_date: '2020-06-01 07:00 +0000',
      id: 1,
      image_banner_url: '/assets/images/challenge-1-banner.png',
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-1-tile.png',
      is_active: true,
      max_participants: 200,
      name: 'Approved Challenge',
      savings_goal: 0,
      savings_kwh: 0,
      savings_updated_dtm: null,
      slug: 'shant-bayanduryan-s-challenge',
      sponsor_facebook: '[Sponsor facebook]',
      sponsor_name: 'Shant Bayanduryan',
      sponsor_twitter: '[Sponsor twitter]',
      sponsor_website: '[Sponsor website]',
      start_date: '2020-03-01 07:00 +0000',
      status: 3,
    },
    {
      authorized: false,
      clean_description: '[Description]',
      created_dtm: 'Sat, 11 Jan 2020 22:25:08 -0000',
      current_participants: 1,
      description: '[Description]',
      description_collective_goal: '[Collective goal]',
      description_eligibility: '[Eligibility description]',
      description_individual_goal: '[Individual goal]',
      description_prizes: '[Pride description]',
      description_rules_url: '[Rules]',
      end_date: '2020-06-01 07:00 +0000',
      id: 1,
      image_banner_url: '/assets/images/challenge-2-banner.png',
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-2-tile.png',
      is_active: true,
      max_participants: 200,
      name: 'Launched Challenge',
      savings_goal: 0,
      savings_kwh: 0,
      savings_updated_dtm: null,
      slug: 'shant-bayanduryan-s-challenge',
      sponsor_facebook: '[Sponsor facebook]',
      sponsor_name: 'Shant Bayanduryan',
      sponsor_twitter: '[Sponsor twitter]',
      sponsor_website: '[Sponsor website]',
      start_date: '2020-03-01 07:00 +0000',
      status: 4,
    },
    {
      authorized: false,
      clean_description: '[Description]',
      created_dtm: 'Sat, 11 Jan 2020 22:25:08 -0000',
      current_participants: 1,
      description: '[Description]',
      description_collective_goal: '[Collective goal]',
      description_eligibility: '[Eligibility description]',
      description_individual_goal: '[Individual goal]',
      description_prizes: '[Pride description]',
      description_rules_url: '[Rules]',
      end_date: '2020-06-01 07:00 +0000',
      id: 1,
      image_banner_url: '/assets/images/challenge-4-banner.png',
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-4-tile.png',
      is_active: true,
      max_participants: 200,
      name: 'Running Challenge',
      savings_goal: 0,
      savings_kwh: 0,
      savings_updated_dtm: null,
      slug: 'shant-bayanduryan-s-challenge',
      sponsor_facebook: '[Sponsor facebook]',
      sponsor_name: 'Shant Bayanduryan',
      sponsor_twitter: '[Sponsor twitter]',
      sponsor_website: '[Sponsor website]',
      start_date: '2020-03-01 07:00 +0000',
      status: 5,
    },
    {
      authorized: false,
      clean_description: '[Description]',
      created_dtm: 'Sat, 11 Jan 2020 22:25:08 -0000',
      current_participants: 1,
      description: '[Description]',
      description_collective_goal: '[Collective goal]',
      description_eligibility: '[Eligibility description]',
      description_individual_goal: '[Individual goal]',
      description_prizes: '[Pride description]',
      description_rules_url: '[Rules]',
      end_date: '2020-06-01 07:00 +0000',
      id: 1,
      image_banner_url: '/assets/images/challenge-2-banner.png',
      image_creator_url: '/assets/images/challenge-2-logo.png',
      image_tile_url: '/assets/images/challenge-2-tile.png',
      is_active: true,
      max_participants: 200,
      name: 'Ended Challenge',
      savings_goal: 0,
      savings_kwh: 0,
      savings_updated_dtm: null,
      slug: 'shant-bayanduryan-s-challenge',
      sponsor_facebook: '[Sponsor facebook]',
      sponsor_name: 'Shant Bayanduryan',
      sponsor_twitter: '[Sponsor twitter]',
      sponsor_website: '[Sponsor website]',
      start_date: '2020-03-01 07:00 +0000',
      status: 6,
    },
  ],
];
