const resource = '/accounts/';

export default class Accounts {
  constructor(api) {
    this.api = api;
  }

  getAll() {
    return this.api.get(`${resource}`);
  }

  getPost(id) {
    return this.api.get(`${resource}/${id}`);
  }

  create(payload) {
    return this.api.post(`${resource}`, payload);
  }

  updateMeter(meterId, payload) {
    return this.api.put(`${resource}meter/${meterId}`, payload);
  }

  delete(id) {
    return this.api.delete(`${resource}${id}`);
  }
}
