import UserProfile from '~/repositories/user';
import Challenges from '~/repositories/challenges';
import Teams from '~/repositories/teams';
import Accounts from '~/repositories/accounts';
import Admin from '~/repositories/admin';

export default ({ app }, inject) => {
  const api = app.$api;
  const repositories = {
    challenges: new Challenges(api),
    teams: new Teams(api),
    accounts: new Accounts(api),
    user: new UserProfile(api),
    admin: new Admin(api),
  };
  inject('repo', repositories);
};
