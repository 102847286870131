export default function({ $auth, store }) {
  if (!$auth.loggedIn) {
    if ($auth.$storage.getUniversal('user')) {
      $auth.setUser($auth.$storage.getUniversal('user'));
    }
  }

  $auth.onError((error, name, endpoint) => {
    $auth.$storage.removeUniversal('user');
    console.error('auth eror', name, error);
  });

  $auth.onRedirect((to, from) => {
    console.error('from', from);
    console.error('to', to);
    // you can optionally change `to` by returning a new value
  });
}
