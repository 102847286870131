import * as challenges from '~/testing/data/challenges';
import * as meterdata from '~/testing/data/meters';

export const registerRoutes = function(mock) {
  mock.onGet('/admin/challenge/approved').reply(200, challenges.challenges[0]);

  mock
    .onGet('/admin/challenge/unapproved')
    .reply(200, challenges.challenges[0]);

  mock.onGet('/admin/challenge/returned').reply(200, challenges.challenges[0]);

  mock.onGet('/challenges/').reply(200, challenges.challenges);
  //   /\/challenges\/.+/
  mock.onGet('/challenges/1').reply(200, challenges.challenge1);

  mock.onGet('/challenges/2').reply(200, challenges.challenge2);

  mock.onGet('/challenges/3').reply(200, challenges.challenge3);

  mock.onGet('/accounts/').reply(200, [
    {
      id: 1,
      utility_name: 'PG&E',
      account_number: 12345675532,
      fuel_type: 'Electric',
      rate: 'Residential',
      address: '3 Elizabeth Lane',
      customer_name: null,
      status: 2,
      status_msg: null,
      is_connected: true,
      meters: [
        {
          id: 1,
          name: 'Meter 1',
          is_active: false,
          address_line_1: '',
          address_line_2: '',
          city: '',
          region: '',
          postal_code: '',
          country: '',
          properties: {},
        },
        {
          id: 2,
          name: 'Meter 2',
          is_active: true,
          address_line_1: '',
          address_line_2: '',
          city: '',
          region: '',
          postal_code: '',
          country: '',
          properties: {},
        },
        {
          id: 3,
          name: 'Meter 3',
          is_active: true,
          address_line_1: '',
          address_line_2: '',
          city: '',
          region: '',
          postal_code: '',
          country: '',
          properties: {},
        },
      ],
    },
    {
      id: 2,
      utility_name: 'PG&E',
      account_number: 48954687651,
      fuel_type: 'Gas',
      rate: 'Commercial',
      address: '2 Ralph Way',
      customer_name: null,
      status: 5,
      status_msg: null,
      is_connected: true,
      meters: [
        {
          id: 3,
          name: 'Meter 1',
          is_active: false,
          address_line_1: '',
          address_line_2: '',
          city: '',
          region: '',
          postal_code: '',
          country: '',
          properties: {},
        },
        {
          id: 4,
          name: 'Meter 2',
          is_active: true,
          address_line_1: '',
          address_line_2: '',
          city: '',
          region: '',
          postal_code: '',
          country: '',
          properties: {},
        },
      ],
    },
    {
      id: 3,
      utility_name: 'Test Utility',
      customer_name: null,
      status: 5,
      status_msg: null,
      is_connected: true,
      meters: [
        {
          id: 5,
          name: '',
          is_active: false,
          address_line_1: '',
          address_line_2: '',
          city: '',
          region: '',
          postal_code: '',
          country: '',
          properties: {},
        },
        {
          id: 6,
          name: '',
          is_active: true,
          address_line_1: '',
          address_line_2: '',
          city: '',
          region: '',
          postal_code: '',
          country: '',
          properties: {},
        },
      ],
    },
  ]);

  mock.onGet('/team/').reply(200, [
    {
      id: 1,
      name: 'The A Team',
      image_url: '',
    },
    {
      id: 2,
      name: "Shant Bay's Team",
      image_url: '',
    },
    {
      id: 3,
      name: '64 Lewis',
      image_url: '',
    },
  ]);

  mock.onGet(/\/team\/.+/).reply(200, {
    id: 1,
    name: 'The A Team',
    image_url: '',
    members: [
      {
        full_name: 'Shant Bayanduryan',
        photo_url:
          'https://s3.amazonaws.com/meterleader-assets/avatars/avatar4.png',
        user_id: 1,
      },
      {
        full_name: 'Natalie Zandt',
        photo_url:
          'https://s3.amazonaws.com/meterleader-assets/avatars/avatar5.png',
        user_id: 3,
      },
    ],
    leader: {
      full_name: 'Shant Bayanduryan',
      photo_url:
        'https://s3.amazonaws.com/meterleader-assets/avatars/avatar4.png',
      user_id: 1,
    },
    leader_in_team: true,
    invitation_link:
      'https://dev.meterleader.com/email_link/team_invite?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0ZWFtX2lkIjo3MCwiZXhwIjoxNTc5NzUzODY3LjM2ODcwNjV9.xN28CTW6b3x2pUnyZrNvsxon2PjGkDMDjLakI_4r1rI',
    meters: [
      {
        id: 2,
        name: 'Electric - PG&E - 3 Elizabeth Lane',
        is_active: true,
      },
    ],
  });

  mock.onGet('/accounts/meter/1/data').reply(200, meterdata.meter1data);

  mock.onPost('/auth/login').reply(200, { msg: 'ok' });

  mock.onGet('/challenges/1/leaderboard').reply(200, [
    {
      name: 'Test',
      image_url: '',
      team_id: 1,
      usage_relative: null,
      members: [
        {
          team_id: 1,
          firstname: 'Shant',
          lastname: 'Bayanduryan',
          photo_url:
            'https://s3.amazonaws.com/meterleader-assets/avatars/avatar2.png',
          user_id: 1,
        },
      ],
      user_in_team: true,
    },
  ]);

  mock.onGet('/challenges/mychallenges').reply(200, challenges.mychallenges);

  mock.onGet('/user/profile').reply(200, {
    msg: 'ok',
    profile: {
      email: 'shant@local.local',
      social_id: null,
      firstname: 'Shant',
      lastname: 'Bayanduryan',
      user_created: 'Fri, 10 Jan 2020 02:38:03 -0000',
      admin: true,
      can_create_challenge: false,
      changing_email_to: 'asg',
      photo_url:
        'https://s3.amazonaws.com/meterleader-assets/avatars/avatar2.png',
      photo_url_large:
        'https://s3.amazonaws.com/meterleader-assets/avatars/avatar2-200.png',
      settings: {
        email_notifications: true,
        challenge_notifications: true,
      },
    },
  });
};
