import { actionTypes } from '@/store/types';
export default async function ({ $auth, redirect, error, store }) {
    if (!$auth.loggedIn) {
        redirect('/login')
    } else {
        await store.dispatch(actionTypes.GET_USER_PROFILE);
        let canCreateChallenge = store.state.user.profile.can_create_challenge
        if (!canCreateChallenge) {
            error({ statusCode: 404, message: 'Page not found' })
        }

    }
}
