const resource = '/user/profile';

export default class UserProfile {
  constructor(api) {
    this.api = api;
  }

  get() {
    return this.api.get(`${resource}`);
  }

  create(payload) {
    return this.api.post(`${resource}`, payload);
  }

  changeName(payload) {
    return this.api.put(`${resource}`, payload);
  }

  update(payload) {
    return this.api.put(`${resource}`, payload);
  }

  delete(id) {
    return this.api.delete(`${resource}${id}`);
  }
}
