import MockAdapter from 'axios-mock-adapter';
import https from 'https';
import {cookie} from "@nuxtjs/auth/lib/module/defaults.js";

export default function({ $axios, redirect, app }, inject) {
  const COOKIE_DOMAIN = process.env.NODE_ENV === 'development' ? 'meterleader.local' : '.meterleader.com';
  const api = $axios.create({
    baseURL: process.env.API_ADDRESS,
    withCredentials: true,
    origin: true,
    xsrfCookieName: 'csrf_access_token',
    xsrfHeaderName: 'X-CSRF-TOKEN',
    httpsAgent: new https.Agent({
      rejectUnauthorized: false //set to false
    })
  });

  // Interceptor to handle JWT refresh and retry request
  // api.interceptors.response.use(
  //   function(response) {
  //     // Any status code that lie within the range of 2xx cause this function to trigger
  //     if (DEBUG_REQUESTS) {
  //       console.log('response', response);
  //     }
  //     return response;
  //   },
  //   function(error) {
  //     // Any status codes that falls outside the range of 2xx cause this function to trigger
  //     if (error.response) {
  //       const {
  //         config,
  //         response: { status },
  //       } = error;
  //       const originalRequest = config;
  //
  //       if (DEBUG_REQUESTS) {
  //         console.log('error', error.response);
  //       }
  //
  //       if (status === 422 || (status === 401 && error.response.data.msg === 'Token has been revoked')) {
  //         return redirect('/logout');
  //       }
  //
  //       if (!(status === 401 && error.response.data.msg === 'Token has expired')) {
  //         if (DEBUG_REQUESTS) {
  //           console.error('401 error', error.response);
  //         }
  //         return Promise.reject(error);
  //       }
  //       return api
  //         .get('/auth/refresh')
  //         .then((result) => {
  //           if (result.headers['set-cookie']) {
  //             originalRequest.headers.cookie = result.headers['set-cookie'][0];
  //           }
  //           return api(originalRequest);
  //         })
  //         .catch((error) => {
  //           if (DEBUG_REQUESTS) {
  //             console.log('jwt refresh error', error);
  //           }
  //           // TODO: redirect to login doesn't currently work
  //           app.$auth.logout();
  //           return redirect('/login');
  //         });
  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //       // http.ClientRequest in node.js
  //       if (DEBUG_REQUESTS) {
  //         console.log('error in request', error.request);
  //       }
  //     } else {
  //       // Something happened in setting up the request that triggered an Error
  //       // eslint-disable-next-line no-lonely-if
  //       if (DEBUG_REQUESTS) {
  //         console.log('Error', error.message);
  //       }
  //     }
  //   }
  // );

  api.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (err.response) {
        if (DEBUG_REQUESTS) {
          console.log('error', err.response);
        }

        // Access Token was expired
        if (err.response.status === 401
          && (err.response.data.msg === 'Token has expired' || err.response.data.msg === 'Missing cookie "access_token_cookie"')
          && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            let res = await api.get('/auth/refresh');
            return api(originalConfig);
          } catch (_error) {
            app.$auth.$storage.removeUniversal('user');
            await app.$auth.logout();
            return redirect('/login');
          }
        }

        if (DEBUG_REQUESTS) {
          console.log('Error', err.message);
        }
      }

      return Promise.reject(err);
    }
  );

  if (process.env.DEMO) {
    const mock = new MockAdapter(api);
    // const mock = new MockAdapter(api, {delayResponse: 400});
    require('~/testing/mock').registerRoutes(mock);
  }

  inject('api', api);
}
