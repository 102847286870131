export const mutationTypes = {
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_UTILITY_ACCOUNTS: 'SET_UTILITY_ACCOUNTS',
  SET_TEAMS: 'SET_TEAMS',
  SET_CHALLENGES: 'SET_CHALLENGES',
  SET_CHALLENGES_CORPORATE: 'GET_CHALLENGES_CORPORATE',
  SET_COMMENTS: 'SET_COMMENTS',
  CLEAR_DISCUSSION_NOTIFICATION: 'CLEAR_DISCUSSION_NOTIFICATION',
};

export const actionTypes = {
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_UTILITY_ACCOUNTS: 'GET_UTILITY_ACCOUNTS',
  GET_TEAMS: 'GET_TEAMS',
  GET_CHALLENGES: 'GET_CHALLENGES',
  GET_CHALLENGES_CORPORATE: 'GET_CHALLENGES_CORPORATE',
  GET_COMMENTS: 'GET_COMMENTS',
};
