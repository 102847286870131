import Vue from 'vue'

const mixin = {
  mounted() {
    this.$titleBar.setTitle(this.$options.pageTitle)
    // if (this.$options.backButtonRoute) {
    //   this.$titleBar.setBackButtonRoute(this.$options.backButtonRoute)
    // }
    this.$titleBar.setBackButtonRoute(this.$options.backButtonRoute)
    this.$titleBar.setRightComponent(this.$options.rightComponent)
  },
  filters: {
    formatDate: function (value) {
      if (!value) {
        return '-'
      }
      let formatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };

      let date = new Date(value);
      return `${date.toLocaleDateString(
        'en-US',
        formatOptions
      )} ${date.toLocaleTimeString()}`;
    },
    formatDateOnly: function (value) {
      if (!value) {
        return '-'
      }
      let formatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };

      let date = new Date(value);
      return `${date.toLocaleDateString(
        'en-US',
        formatOptions
      )} `;
    },
    round: function (value) {
      return Math.round(value)
    }
  },
}

Vue.mixin(mixin)

Vue.prototype.$alert = {
  data: Vue.observable({
    message: "Default alert message",
    showModal: false
  }),
  promiseResolve: null,
  init: function (message) {
    this.data.message = message
    this.data.showModal = true
    return new Promise((resolve, reject) => {
      this.promiseResolve = resolve
    });
  }
}

Vue.prototype.$titleBar = {
  data: Vue.observable({
    title: "Meterleader",
    backButtonRoute: {},
    rightComponent: null
  }),
  setTitle: function (title) {
    if (title) {
      this.data.title = title
    }
  },
  setBackButtonRoute(route) {
    this.data.backButtonRoute = route
  },

  setRightComponent(component) {
    this.data.rightComponent = component
  }

}
