const middleware = {}

middleware['admin_dashboard'] = require('../middleware/admin_dashboard.js')
middleware['admin_dashboard'] = middleware['admin_dashboard'].default || middleware['admin_dashboard']

middleware['check_login'] = require('../middleware/check_login.js')
middleware['check_login'] = middleware['check_login'].default || middleware['check_login']

middleware['check_organization_admin'] = require('../middleware/check_organization_admin.js')
middleware['check_organization_admin'] = middleware['check_organization_admin'].default || middleware['check_organization_admin']

middleware['check_pelm_cap'] = require('../middleware/check_pelm_cap.js')
middleware['check_pelm_cap'] = middleware['check_pelm_cap'].default || middleware['check_pelm_cap']

middleware['stats'] = require('../middleware/stats.js')
middleware['stats'] = middleware['stats'].default || middleware['stats']

export default middleware
